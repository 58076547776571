import styles from "./Navbar.module.css";
import logo from "../../Assets/image 9logo.png";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { Dropdown } from "./components/Dropdown";

import {
  ShoppingCartRounded,
  MusicNote,
  MusicOff,
  Menu,
  Close,
} from "@mui/icons-material";
import { useState } from "react";

function Navbar({
  isMusicPlaying,
  musicClickHandler,
  tags,
  mobileDrawerOpen,
  mobileDrawerClickHandler,
}) {
  const [collectionDropdown, setCollectionDropdownOpen] = useState(false);

  function toggleCollection() {
    setCollectionDropdownOpen(!collectionDropdown);
  }
  return (
    <>
      <nav>
        <NavHashLink smooth to="/#home">
          <img style={{ cursor: "pointer" }} src={logo} alt="logo" />
        </NavHashLink>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <NavHashLink smooth to="/#home">
              <p>Home</p>
            </NavHashLink>
          </li>
          <li className={styles.navItem}>
            <NavHashLink smooth to="/#about">
              <p>About</p>
            </NavHashLink>
          </li>
          <NavHashLink smooth to="/#collection">
            <li
              className={styles.navItem}
              onMouseEnter={() => setCollectionDropdownOpen(true)}
              onMouseLeave={() => setCollectionDropdownOpen(false)}
            >
              <p className={collectionDropdown ? styles.activeLink : ""}>
                Collection
              </p>
              {collectionDropdown && (
                <Dropdown toggleCollection={toggleCollection} tags={tags} />
              )}
            </li>
          </NavHashLink>
          <li className={styles.navItem}>
            <NavHashLink smooth to="/#reviews">
              <p>Reviews</p>
            </NavHashLink>
          </li>
          <li className={styles.navItem}>
            <NavHashLink smooth to="/#contact">
              <p>Contact</p>
            </NavHashLink>
          </li>
        </ul>
        <div className={styles.extraButtons}>
          <NavLink
            to="cart"
            className={({ isActive }) =>
              isActive ? styles.iconBtnDivActive : styles.iconBtnDiv
            }
          >
            <ShoppingCartRounded />
          </NavLink>

          {/* {isMusicPlaying ? (
            <MusicNote
              title="Turn off song"
              onClick={musicClickHandler}
              className={styles.musicBtn}
            />
          ) : (
            <MusicOff
              titlle="Turn on song"
              onClick={musicClickHandler}
              className={styles.musicBtn}
            />
          )} */}
        </div>

        <div className={styles.menuBtn}>
          {!mobileDrawerOpen && (
            <Menu
              className={styles.menuBtnIcon}
              onClick={mobileDrawerClickHandler}
            />
          )}
          {mobileDrawerOpen && (
            <Close
              className={styles.menuBtnIcon}
              onClick={mobileDrawerClickHandler}
            />
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
