import React, { useState } from "react";
import styles from "./Contact.module.css";

import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Fade from "react-reveal";
import { sendPushNotifications } from "../utilities/utilities";

const Contact = ({ contact }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [msg, setMsg] = useState("");

  const contactHandler = async () => {
    if (name === "") {
      setMsg("Please enter name");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }
    if (phone === "") {
      setMsg("Please enter phone number");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }
    if (message === "") {
      setMsg("Please enter your message/query");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }

    const title = "Message from Contact section";
    const body = `${name}(Phone:${phone}) sent a message: "${message}"`;
    await sendPushNotifications(title, body);

    toast.success("Your message was sent, you will be contacted soon!", {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "white",
        border: "2px solid #3d2d10",
        color: "#3d2d10",
      },
      icon: false,
      autoClose: 3000,
      hideProgressBar: true,
    });

    setName("");
    setPhone("");
    setMessage("");
    setMsg("");
  };
  return (
    <>
      <ToastContainer />
      <div id="contact" className={styles.contactMainDiv}>
        <div className={styles.contactDiv}>
          <Fade top duration={2000}>
            <h1 className={styles.mainHeading}>Contact</h1>
          </Fade>
          <div className={styles.contactSubDiv}>
            <div className={styles.left}>
              <div className={styles.linkItem}>
                <a
                  title="Gmail"
                  href={"mailto:" + contact["email"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => window.open(e.currentTarget.href, "_blank")}
                >
                  <EmailIcon className={styles.iconStyle} />
                  <p>{contact["email"]}</p>
                </a>
              </div>
              <div className={styles.linkItem}>
                <a
                  title="Whatsapp"
                  href={"https://wa.me/" + contact["whatsapp"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => window.open(e.currentTarget.href, "_blank")}
                >
                  <WhatsAppIcon className={styles.iconStyle} />
                  <p>{contact["whatsapp"]}</p>
                </a>
              </div>
              <div className={styles.linkItem}>
                <a
                  title="Phone"
                  href={"tel:" + contact["phone"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => window.open(e.currentTarget.href, "_blank")}
                >
                  <LocalPhoneIcon className={styles.iconStyle} />
                  <p>{contact["phone"]}</p>
                </a>
              </div>
              <div className={styles.linkItem}>
                <a
                  title="Instagram"
                  href={contact["instagram"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => window.open(e.currentTarget.href, "_blank")}
                >
                  <InstagramIcon className={styles.iconStyle} />
                  <p>{contact["instagram"].split("/").at(-1)}</p>
                </a>
              </div>
              <div className={styles.linkItem}>
                <a
                  title="Address"
                  href={contact["lat"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => window.open(e.currentTarget.href, "_blank")}
                >
                  <LocationOnIcon className={styles.iconStyle} />
                  <div className={styles.mapDiv}>
                    <p>{contact["address"]}</p>
                    <iframe
                      title="address"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d235.55159471964862!2d72.83935551289754!3d19.15911355035314!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b772a3b0767f%3A0x359ddf1d3f15fdfd!2sKAIROS%20VENEERS%20%26%20PLY!5e0!3m2!1sen!2sin!4v1695959320020!5m2!1sen!2sin"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </a>
              </div>
            </div>
            <div className={styles.right}>
              <p className={styles.dropMsgText}>
                <strong>Drop us a message or query</strong>
              </p>
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Name"
              />
              <input
                type="text"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                placeholder="Phone"
              />
              <textarea
                rows="15"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Enter your message"
              />
              <button onClick={contactHandler}>Send</button>
              <p
                style={{
                  fontSize: "15px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <strong>{msg}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
