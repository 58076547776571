import React, { useState } from "react";
import styles from "../Item.module.css";
import CropFreeIcon from "@mui/icons-material/CropFree";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

export const GroupCard = ({ i, handlePreviewClick, handleAddToCartClick }) => {
  const [value, setValue] = useState("def");

  const handleChange = (event) => {
    setValue(event.target.value);
    event.preventDefault();
  };
  return (
    <div className={styles.variantDiv}>
      <div className={styles.previewImgDiv}>
        <img alt="img" src={i["img"]} />
        <div
          onClick={() => {
            handlePreviewClick(i["img"]);
          }}
          className={styles.iconDiv}
        >
          <CropFreeIcon className={styles.icon} />
        </div>
      </div>
      <div className={styles.variantRightDiv}>
        <h1>Group:</h1>
        <h1>{i["grp_no"]}</h1>
        <select value={value} onChange={handleChange} className="minimal">
          <option value="def" disabled selected>
            View available sizes
          </option>
          {i["available_sizes"].map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </select>
        <div
          className={styles.viewMoreBtn}
          onClick={() => {
            if (value === "def") {
              window.alert("Please select a size first, and then try adding!");
              return;
            }
            handleAddToCartClick(i["id"], value);
          }}
        >
          <AddShoppingCartIcon /> Add To Cart
        </div>
      </div>
    </div>
  );
};
