import React, { useEffect } from "react";
import "./Utilities.css";
import CloseIcon from "@mui/icons-material/Close";
function disableScroll() {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

function enableScroll() {
  window.onscroll = function () {};
}

const ImageOverlay = ({ img, handleClick }) => {
  useEffect(() => {
    disableScroll();
  }, []);
  return (
    <div className="overlayDiv">
      <div
        className="iconDiv"
        onClick={() => {
          enableScroll();
          handleClick(false, "");
        }}
      >
        <CloseIcon className="icon" />
      </div>
      <img alt="img" className="overlayImg" src={img} />
    </div>
  );
};

export default ImageOverlay;
