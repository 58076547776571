import "./App.css";
import bgImage from "./Assets/background.png";
import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import navStyles from "./Components/home/Navbar.module.css";
import homeStyles from "./Components/home/Home.module.css";
import MobileNavbar from "./Components/home/components/MobileNavbar";
import { Slide } from "react-reveal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

//icons

//pages
import Navbar from "./Components/home/Navbar";
import Home from "./Components/home/Home";
import TagCollections from "./Components/collections/TagCollections";
import Cart from "./Components/cart/Cart";
import Gallery from "./Components/gallery/Gallery";
import Collection from "./Components/collections/Collection";
import Item from "./Components/collections/Item";
import Footer from "./Components/home/Footer";
import ScrollToTop from "./Components/utilities/ScrollToTop";
import { getDriveDirectDownloadLink } from "./Components/utilities/utilities";

function disableScroll() {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

function enableScroll() {
  window.onscroll = function () {};
}

function App({ data, setLoading }) {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [audio] = useState(new Audio());

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });

    if (mobileDrawerOpen) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [mobileDrawerOpen]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function musicClickHandler() {
    if (isMusicPlaying) {
      handleStop();
    } else {
      var songLink = "";
      for (let i = 0; i < data["songs"].length; i++) {
        if (data["songs"][i].play) {
          songLink = data["songs"][i].link;
        }
      }
      handlePlay(songLink);
    }
    setIsMusicPlaying(!isMusicPlaying);
  }

  function mobileDrawerClickHandler() {
    setMobileDrawerOpen(!mobileDrawerOpen);
  }

  const handlePlay = (songLink) => {
    const downloadableLink = getDriveDirectDownloadLink(songLink);
    audio.src = downloadableLink;
    audio.loop = true;
    audio.play();
  };

  const handleStop = () => {
    audio.pause();
    audio.currentTime = 0;
  };

  // const tags = [
  //   "Veneers",
  //   "Oaks",
  //   "Laminates",
  //   "Acrylics",
  //   "3D Designs",
  //   "Custom Designs",
  // ];

  // const reviews = [
  //   {
  //     id: "1",
  //     name: "Aniket 1",
  //     text: "Their service was great, I was helped with everything I needed. Recommended!",
  //     type: "Customer",
  //     email: "",
  //     phone: "",
  //   },
  //   {
  //     id: "1",
  //     name: "Aniket 2",
  //     text: "Their service was great!",
  //     type: "Architect",
  //     email: "",
  //     phone: "1234567890",
  //   },
  //   {
  //     id: "1",
  //     name: "Aniket 3",
  //     text: "Their service was great, I was helped with everything I needed. Recommended!",
  //     type: "Customer",
  //     email: "",
  //     phone: "",
  //   },
  //   {
  //     id: "1",
  //     name: "Aniket 4",
  //     text: "Great!",
  //     type: "Architect",
  //     email: "a@gmail.com",
  //     phone: "",
  //   },
  //   {
  //     id: "1",
  //     name: "Aniket 5",
  //     text: "Their service was great, I was helped with everything I needed. Recommended!",
  //     type: "Customer",
  //     email: "",
  //     phone: "",
  //   },
  //   {
  //     id: "1",
  //     name: "Aniket 6",
  //     text: "Their service was great, I was helped with everything I needed. Recommended!",
  //     type: "Architect",
  //     email: "",
  //     phone: "",
  //   },
  // ];

  // const contact = {
  //   address:
  //     "Motilal Nagar, Goregaon West, Mumbai, Maharashtra, Indiaaa and i am writing this text very long to test",
  //   email: "kairos@gmail.com",
  //   instagram: "https://instagram.com/kairos_veneers",
  //   lat: "https://www.google.com/maps/place/KAIROS+VENEERS+%26+PLY/@19.1591136,72.8393555,21z/data=!4m6!3m5!1s0x3be7b772a3b0767f:0x359ddf1d3f15fdfd!8m2!3d19.1590961!4d72.8393508!16s%2Fg%2F11grc0q74b?entry=ttu",
  //   phone: "+91 1234567890",
  //   whatsapp: "+91 1234567890",
  // };
  return (
    <>
      <div>
        <ScrollToTop />
        <header>
          <Navbar
            isMusicPlaying={isMusicPlaying}
            musicClickHandler={musicClickHandler}
            tags={data["tags"]}
            mobileDrawerOpen={mobileDrawerOpen}
            mobileDrawerClickHandler={mobileDrawerClickHandler}
            songs={data["songs"]}
          />
        </header>

        {showTopBtn && (
          <div onClick={goToTop} className={homeStyles.arrowToTop}>
            <ArrowUpwardIcon className={homeStyles.icon} />
          </div>
        )}
        <div className={navStyles.mobileMainNavDiv}>
          {mobileDrawerOpen && (
            <Slide right>
              <div className={navStyles.mobileBtnSection}>
                <div className={navStyles.mobileSecDiv}>
                  <MobileNavbar
                    isMusicPlaying={isMusicPlaying}
                    musicClickHandler={musicClickHandler}
                    mobileDrawerOpen={mobileDrawerOpen}
                    mobileDrawerClickHandler={mobileDrawerClickHandler}
                    songs={data["songs"]}
                  />
                </div>
              </div>
            </Slide>
          )}
        </div>
        <main className="App" style={{ backgroundImage: `url(${bgImage})` }}>
          <Routes>
            <Route
              index
              element={
                <Home
                  tags={data["tags"]}
                  reviews={data["reviews"]}
                  contact={data["contact"]}
                  about1={data["about_1"]}
                  about2={data["about_2"]}
                  new1={data["top_img1"]}
                  new2={data["top_img2"]}
                />
              }
            />
            <Route
              path="tags/:tagName"
              element={<TagCollections setLoading={setLoading} />}
            />
            <Route
              path="tags/:tagName/:collID"
              element={<Collection setLoading={setLoading} />}
            />
            <Route
              path="item/:itemID"
              element={<Item setLoading={setLoading} />}
            />
            <Route path="cart" element={<Cart setLoading={setLoading} />} />
            <Route
              path="gallery/:galleryID"
              element={<Gallery setLoading={setLoading} />}
            />
          </Routes>
          <Footer contact={data["contact"]} tags={data["tags"]} />
        </main>
      </div>
    </>
  );
}

export default App;
