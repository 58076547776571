import React, { useEffect, useState } from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Loading from "./Components/utilities/Loading";
import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

const AppWrap = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    //fetch 'home' doc from 'basic' collectio
    const docRef = doc(db, "basic", "home");
    const docSnap = await getDoc(docRef);
    const fetchedData = docSnap.data();
    console.log(fetchedData);
    setData(fetchedData);

    setLoading(false);
  };
  return (
    <BrowserRouter>
      {loading ? <Loading /> : <App data={data} setLoading={setLoading} />}
    </BrowserRouter>
  );
};

export default AppWrap;
