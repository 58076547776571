import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import styles from "./Collection.module.css";
import { useEffect, useState } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import ImageOverlay from "../utilities/ImageOverlay";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import Loading from "../utilities/Loading";
import { isEmpty } from "../utilities/utilities";

function Collection() {
  const location = useLocation();
  const collectionIdFromPath = location.pathname
    .split("/")
    .at(-1)
    .replaceAll("%20", " ");

  const tagNameFromPath = location.pathname
    .split("/")
    .at(-2)
    .replaceAll("%20", " ");

  const [collectionData, setCollectionData] = useState({});
  const [items, setItems] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showImageSrc, setShowImageSrc] = useState("");
  const [loading, setLoading] = useState(true);

  const handlePreviewClick = (src) => {
    setShowImage(!showImage);
    setShowImageSrc(src);
  };

  useEffect(() => {
    if (location.state === null) {
      fetchCollectionAndItems();
    } else {
      setCollectionData(location.state.collection);
      fetchItem(location.state.collection);
    }
  }, []);

  const fetchCollectionAndItems = async () => {
    setLoading(true);
    const docRef = doc(db, "collections", tagNameFromPath);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const fetchedData = docSnap.data();
      const collectionData = fetchedData["collections"];
      var coll = {};
      for (let i = 0; i < collectionData.length; i++) {
        if (collectionData[i]["id"] === collectionIdFromPath) {
          coll = collectionData[i];
          setCollectionData(coll);
          break;
        }
      }
      if (!isEmpty(coll)) {
        fetchItem(coll);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const fetchItem = async (collection) => {
    setLoading(true);

    const itemIds = collection["items"];
    var fetchedItems = [];

    for (let i = 0; i < itemIds.length; i++) {
      //fetch 'id' doc from 'items' collection
      const docRef = doc(db, "items", itemIds[i]);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const fetchedData = docSnap.data();
        fetchedItems.push(fetchedData);
      }
    }

    fetchedItems = fetchedItems.map((item) => ({
      ...item,
      collection: collection["name"],
    }));
    setItems(fetchedItems);

    setLoading(false);
  };
  return (
    <>
      {showImage && (
        <ImageOverlay img={showImageSrc} handleClick={handlePreviewClick} />
      )}
      <div
        className={
          items.length === 0 ? styles.NoCollectionMainDiv : styles.MainDiv
        }
      >
        {loading ? (
          <Loading />
        ) : items.length === 0 ? (
          <h1>{"No items at the moment!"}</h1>
        ) : (
          <div>
            <h1 className={styles.heading}>{collectionData["name"]}</h1>
            <div className={styles.collectionsMainDiv}>
              {items.map((i) => (
                <div key={i["name"]} className={styles.collectionDiv}>
                  <div className={styles.previewImgDiv}>
                    <img alt="img" src={i["img"]} />
                    <div
                      onClick={() => {
                        handlePreviewClick(i["img"]);
                      }}
                      className={styles.iconDiv}
                    >
                      <CropFreeIcon className={styles.icon} />
                    </div>
                  </div>
                  <div className={styles.collectionRightDiv}>
                    <h1>{i["name"]}</h1>
                    {i["variants"].length === 0 ? (
                      <div />
                    ) : (
                      <NavLink
                        to={`/item/${i["id"]}`}
                        className={styles.viewMoreBtn}
                        state={{ item: i }}
                      >
                        <div>View Groups</div>
                      </NavLink>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Collection;
