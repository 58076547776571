import React from "react";
import styles from "./Footer.module.css";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Footer = ({ contact, tags }) => {
  return (
    <div className={styles.mainFooterDiv}>
      <div className={styles.footerDiv}>
        <div className={styles.leftDiv}>
          <h1>Quick Links</h1>
          <div style={{ height: "2vh" }} />
          <NavHashLink smooth to="/#home">
            <p>Home</p>
          </NavHashLink>
          <NavHashLink smooth to="/#about">
            <p>About us</p>
          </NavHashLink>
          <NavHashLink smooth to="/#collection">
            <p>Collections</p>
          </NavHashLink>
          <NavHashLink smooth to="/#reviews">
            <p>Reviews</p>
          </NavHashLink>
          <NavHashLink smooth to="/#contact">
            <p>Contact</p>
          </NavHashLink>
        </div>
        <div className={styles.rightDiv}>
          <h1>Collections</h1>
          <div style={{ height: "2vh" }} />
          {tags.map((i) => (
            <NavLink key={i} className={styles.collItem} to={"/tags/" + i}>
              <p>{i}</p>
            </NavLink>
          ))}
        </div>
      </div>
      <div className={styles.iconsDiv}>
        <a
          title="Gmail"
          href={"mailto:" + contact["email"]}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => window.open(e.currentTarget.href, "_blank")}
        >
          <EmailIcon className={styles.iconStyle} />
        </a>
        <a
          title="Whatsapp"
          href={"https://wa.me/" + contact["whatsapp"]}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => window.open(e.currentTarget.href, "_blank")}
        >
          <WhatsAppIcon className={styles.iconStyle} />
        </a>

        <a
          title="Phone"
          href={"tel:" + contact["phone"]}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => window.open(e.currentTarget.href, "_blank")}
        >
          <LocalPhoneIcon className={styles.iconStyle} />
        </a>

        <a
          title="Instagram"
          href={contact["instagram"]}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => window.open(e.currentTarget.href, "_blank")}
        >
          <InstagramIcon className={styles.iconStyle} />
        </a>
        <a
          title="Address"
          href={contact["lat"]}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => window.open(e.currentTarget.href, "_blank")}
        >
          <LocationOnIcon className={styles.iconStyle} />
        </a>
      </div>
      <div className={styles.copyrightDiv}>
        <p>Copyright © 2023 Nitin Enterprises</p>
        <p>Kairos®</p>
      </div>
    </div>
  );
};

export default Footer;
