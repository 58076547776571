import { useLocation } from "react-router-dom";
import styles from "./Gallery.module.css";
import ImageOverlay from "../utilities/ImageOverlay";
import { useState, useEffect } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import {
  contactArchitectTitle,
  isEmpty,
  sendPushNotifications,
} from "../utilities/utilities";
import CallIcon from "@mui/icons-material/Call";
import GetContactOverlay from "../utilities/GetContactOverlay";
import Loading from "../utilities/Loading";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Gallery() {
  const [reviewDetail, setReviewDetail] = useState({});
  const location = useLocation();
  const galleryIDFromPath = location.pathname
    .split("/")
    .at(-1)
    .replaceAll("%20", " ");

  const [images, setImages] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showImageSrc, setShowImageSrc] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [loading, setLoading] = useState(true);

  const handlePreviewClick = (src) => {
    setShowImage(!showImage);
    setShowImageSrc(src);
  };

  const handleContactClick = () => {
    setShowContactDetails(!showContactDetails);
  };

  //function to send email to the artchitect(artchitect phone/email from reviewDetail['phone']/reviewDetail['email'])
  //email body contains the user details fetched from name and phone got from contact overlay
  //function to send email to kairos whatsapp -> that an email with this body was sent to this architect
  const contactArchitect = async (name, phone) => {
    setLoading(true);
    var title = "Architect was Contacted";
    var body = `An Architect(Name:${reviewDetail["name"]}${
      reviewDetail["phone"] !== "" ? `,Phone:${reviewDetail["phone"]}` : ""
    }${
      reviewDetail["email"] !== "" ? `,Email:${reviewDetail["email"]}` : ""
    }) was contacted by ${name}(Phone:${phone})`;
    await sendPushNotifications(title, body);

    toast.success(
      "Your details were sent to the Architect, you will be contacted soon!",
      {
        position: toast.POSITION.BOTTOM_CENTER,
        style: {
          backgroundColor: "white",
          border: "2px solid #3d2d10",
          color: "#3d2d10",
        },
        icon: false,
        autoClose: 6000,
        hideProgressBar: true,
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    if (location.state != null && location.state.review != null) {
      setReviewDetail(location.state.review);
    }

    fetchImagesById();
  }, []);

  const fetchImagesById = async () => {
    setLoading(true);

    //fetch 'gallery' doc from 'basic' collection
    const docRef = doc(db, "basic", "gallery");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const fetchedData = docSnap.data();

      if (galleryIDFromPath in fetchedData) {
        setImages(fetchedData[galleryIDFromPath]);
      }
    }

    setLoading(false);
  };
  return (
    <>
      <ToastContainer />
      {!isEmpty(reviewDetail) &&
        reviewDetail["type"] === "Architect" &&
        (reviewDetail["phone"] !== "" || reviewDetail["email"] !== "") && (
          <div onClick={handleContactClick} className={styles.contactBtn}>
            <CallIcon />
            Contact Architect
          </div>
        )}
      {showImage && (
        <ImageOverlay img={showImageSrc} handleClick={handlePreviewClick} />
      )}
      {showContactDetails && (
        <GetContactOverlay
          title={contactArchitectTitle}
          handleClick={handleContactClick}
          handleContactClick={contactArchitect}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <div
          className={
            images.length === 0 ? styles.NoImageMainDiv : styles.MainDiv
          }
        >
          {images.length === 0 ? (
            <h1>No Images Available In Gallery</h1>
          ) : (
            <div>
              <h1 className={styles.heading}>Gallery</h1>
              <div className={styles.galleryMainDiv}>
                {images.map((i) => (
                  <div key={i} className={styles.previewImgDiv}>
                    <img alt="img" src={i} />
                    <div
                      onClick={() => {
                        handlePreviewClick(i);
                      }}
                      className={styles.iconDiv}
                    >
                      <CropFreeIcon className={styles.icon} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Gallery;
