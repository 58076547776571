import React from "react";
import styles from "./Collections.module.css";
import Fade from "react-reveal";
import { NavLink } from "react-router-dom";

const Collections = ({ tags }) => {
  return (
    <div id="collection" className={styles.collMainDiv}>
      <div className={styles.collDiv}>
        <Fade top duration={2000}>
          <h1 className={styles.mainHeading}>Collections</h1>
        </Fade>
        <div className={styles.btnsDiv}>
          {tags.map((i) => (
            <div key={i} className={styles.btnWrapper}>
              <NavLink className={styles.btn} to={"/tags/" + i}>
                <p className={styles.btnText}>{i}</p>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Collections;
