import { useLocation } from "react-router-dom";
import styles from "./TagCollections.module.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import ImageOverlay from "../utilities/ImageOverlay";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import Loading from "../utilities/Loading";

function TagCollections() {
  const location = useLocation();
  const tag = location.pathname.split("/").at(-1).replaceAll("%20", " ");
  const [collections, setCollections] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showImageSrc, setShowImageSrc] = useState("");
  const [loading, setLoading] = useState(true);

  const handlePreviewClick = (src) => {
    setShowImage(!showImage);
    setShowImageSrc(src);
  };

  useEffect(() => {
    fetchCollection(tag);
  }, [tag]);

  const fetchCollection = async (tag) => {
    setLoading(true);

    //fetch 'tag' doc from 'collections' collection
    const docRef = doc(db, "collections", tag);
    const docSnap = await getDoc(docRef);
    console.log(docSnap.exists());
    if (docSnap.exists()) {
      const fetchedData = docSnap.data();
      setCollections(fetchedData["collections"]);
    }

    setLoading(false);
  };
  return (
    <>
      {showImage && (
        <ImageOverlay img={showImageSrc} handleClick={handlePreviewClick} />
      )}
      <div
        className={
          collections.length === 0 ? styles.NoItemTagDiv : styles.TagDiv
        }
      >
        {loading ? (
          <Loading />
        ) : collections.length === 0 ? (
          <h1>No Collections available for {tag}</h1>
        ) : (
          <div>
            <h1 className={styles.heading}>{tag}</h1>
            <div className={styles.collectionsMainDiv}>
              {collections.map((i) => (
                <div key={i["name"]} className={styles.collectionDiv}>
                  <div className={styles.previewImgDiv}>
                    <img src={i["img"]} />
                    <div
                      onClick={() => {
                        handlePreviewClick(i["img"]);
                      }}
                      className={styles.iconDiv}
                    >
                      <CropFreeIcon className={styles.icon} />
                    </div>
                  </div>
                  <div className={styles.collectionRightDiv}>
                    <h1>{i["name"]}</h1>
                    <p>
                      {i["sub_text"].trim() !== "" ? `"${i["sub_text"]}"` : ""}
                    </p>
                    {i["items"].length === 0 ? (
                      <div />
                    ) : (
                      <NavLink
                        className={styles.viewMoreBtn}
                        to={`/tags/${tag}/${i["id"]}`}
                        state={{ collection: i }}
                      >
                        <div>View More</div>
                      </NavLink>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TagCollections;
