import { About } from "./About";
import { Intro } from "./Intro";
import { About2 } from "./About2";
import Collections from "./Collections";
import Reviews from "./Reviews";
import Contact from "./Contact";

function Home({ tags, reviews, contact, about1, about2, new1, new2 }) {
  return (
    <>
      <Intro tags={tags} />
      <About new1={new1} new2={new2} />
      <About2 about1={about1} about2={about2} />
      <Collections tags={tags} />
      <Reviews reviews={reviews} />
      <Contact contact={contact} />
    </>
  );
}

export default Home;
