import React from "react";
import styles from "./MobileNavbar.module.css";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

import { ShoppingCartRounded, MusicNote, MusicOff } from "@mui/icons-material";

const MobileNavbar = ({
  isMusicPlaying,
  musicClickHandler,
  mobileDrawerOpen,
  mobileDrawerClickHandler,
}) => {
  return (
    <div className={styles.navList}>
      <div className={styles.navItem}>
        <NavHashLink
          onClick={mobileDrawerClickHandler}
          smooth
          to="/#home"
          activeStyle={{ color: "black" }}
        >
          <p>Home</p>
        </NavHashLink>
      </div>
      <div className={styles.navItem}>
        <NavHashLink
          onClick={mobileDrawerClickHandler}
          smooth
          to="/#about"
          activeStyle={{ color: "black" }}
        >
          <p>About</p>
        </NavHashLink>
      </div>
      <div className={styles.navItem}>
        <NavHashLink
          onClick={mobileDrawerClickHandler}
          smooth
          to="/#collection"
          activeStyle={{ color: "black" }}
        >
          <p>Collections</p>
        </NavHashLink>
      </div>
      <div className={styles.navItem}>
        <NavHashLink
          onClick={mobileDrawerClickHandler}
          smooth
          to="/#reviews"
          activeStyle={{ color: "black" }}
        >
          <p>Reviews</p>
        </NavHashLink>
      </div>
      <div className={styles.navItem}>
        <NavHashLink
          onClick={mobileDrawerClickHandler}
          smooth
          to="/#contact"
          activeStyle={{ color: "black" }}
        >
          <p>Contact</p>
        </NavHashLink>
      </div>
      <div className={styles.navItem}>
        <NavLink
          to="cart"
          onClick={mobileDrawerClickHandler}
          className={({ isActive }) =>
            isActive ? styles.iconBtnDivActive : styles.iconBtnDiv
          }
        >
          <ShoppingCartRounded className={styles.icon} />
          <div style={{ marginRight: "10px" }} />
          <p>Cart</p>
        </NavLink>
      </div>
      {/* <div className={styles.navItem}>
        {isMusicPlaying ? (
          <div
            title="Turn off song"
            onClick={musicClickHandler}
            className={styles.iconBtnDiv}
          >
            <MusicNote className={styles.icon} />
            <div style={{ marginRight: "10px" }} />
            <p>Music On</p>
          </div>
        ) : (
          <div
            titlle="Turn on song"
            onClick={musicClickHandler}
            className={styles.iconBtnDiv}
          >
            <MusicOff className={styles.icon} />
            <div style={{ marginRight: "10px" }} />
            <p>Music Off</p>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default MobileNavbar;
