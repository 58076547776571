import React from "react";
import styles from "./Intro.module.css";
import Fade from "react-reveal/Fade";
import Typewriter from "typewriter-effect";

import { NavHashLink } from "react-router-hash-link";

export const Intro = ({ tags }) => {
  return (
    <div id="home" className={styles.mainIntroDiv}>
      <div className={styles.introDiv}>
        <div className={styles.mobSpacer}></div>
        <div className={styles.introDivText}>
          <Fade top duration={2000}>
            <h1
              style={{
                fontSize: "50px",
                fontFamily: "Playfair Display, serif",
              }}
            >
              Design a Home you just can't wait to go back to,
            </h1>
          </Fade>
          <Fade top duration={2000}>
            <h3
              style={{
                fontSize: "20px",
              }}
            >
              Express your style and design home that reflects your aesthetics.
            </h3>
          </Fade>
          <div style={{ height: "5vh" }} />
          <Fade top duration={2000}>
            <div className={styles.typerText}>
              <p
                style={{
                  fontWeight: "bold",
                }}
              >
                We offer
              </p>
              &nbsp;
              <Typewriter
                style={{ fontWeight: "bold" }}
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: tags,
                }}
              />
            </div>
          </Fade>
          <div style={{ height: "2vh" }} />
          <Fade top duration={2000}>
            <NavHashLink smooth to="/#collection">
              <div className={styles.viewBtn}>View Now</div>
            </NavHashLink>
          </Fade>
        </div>
        <Fade duration={3000}>
          <img
            className={styles.introDivImg}
            alt="img"
            src="https://static.remove.bg/sample-gallery/graphics/bird-thumbnail.jpg"
          />
        </Fade>
      </div>
      {/* <div
        style={{
          transform: `scale(${(offsetY / window.screen.height) * 10})`,
        }}
        className={styles.mainLogoDiv}
      >
        <img src={logo} />
        <div className={styles.logoTextDiv}>
          <p className={styles.mainLogoText}>KAIROS</p>
          <p className={styles.mainLogoSubText}>Veneers &Ply</p>
        </div>
      </div> */}
    </div>
  );
};
