import axios from "axios";
import { FCMServerKey, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

const getDriveDirectDownloadLink = (driveLink) => {
  const fileId = driveLink.match(/\/d\/([a-zA-Z0-9_-]+)\//)[1];
  return `https://drive.google.com/uc?export=download&id=${fileId}`;
};

function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}

const getCurrentFormattedTime = () => {
  var currentdate = new Date();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  var datetime = currentdate.toLocaleDateString("en-US", options);
  return datetime;
};

const contactArchitectTitle = "Contact Architect";
const addToCartTitle = "Add To Cart";

const sendPushNotifications = async (title, body) => {
  const docRef = doc(db, "admin", "fcm_tokens");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const fcms = docSnap.data();

    Object.entries(fcms).map(async ([key, value]) => {
      await FCMNotificationAPI(value, title, body);
    });
  }
};

const FCMNotificationAPI = async (toFCMToken, title, body) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `key=${FCMServerKey}`,
  };
  const data = {
    to: toFCMToken,
    notification: {
      title: title,
      body: body,
    },
  };
  await axios.post("https://fcm.googleapis.com/fcm/send", data, {
    headers: headers,
  });
};

export {
  isEmpty,
  contactArchitectTitle,
  addToCartTitle,
  sendPushNotifications,
  getCurrentFormattedTime,
  getDriveDirectDownloadLink,
};
