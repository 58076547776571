import React from "react";
import styles from "./Loading.module.css";
import { Watch } from "react-loader-spinner";

const Loading = () => {
  return (
    <div className={styles.mainLoadingDiv}>
      <Watch
        height="200"
        width="200"
        radius="48"
        color="white"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};

export default Loading;
