import React from "react";
import styles from "./Reviews.module.css";
import Fade from "react-reveal";
import "./components/Carousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from "swiper/modules";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { NavLink } from "react-router-dom";

const Reviews = ({ reviews }) => {
  return (
    <div id="reviews" className={styles.reviewsMainDiv}>
      <div className={styles.reviewsDiv}>
        <Fade top duration={2000}>
          <h1 className={styles.mainHeading}>Reviews</h1>
        </Fade>
        <div className={styles.reviewsCarousel}>
          <Swiper
            loop={true}
            centeredSlides={true}
            effect={"coverflow"}
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 20,
              modifier: 5,
              slideShadows: false,
            }}
            navigation={{ clickable: true }}
            modules={[Pagination, Navigation, Autoplay, EffectCoverflow]}
            className="mySwiper"
          >
            {reviews.map((i) => (
              <SwiperSlide key={i["name"]}>
                {({ isActive }) =>
                  isActive ? (
                    <div className={styles.reviewCardDiv}>
                      <div className={styles.reviewCardActive}>
                        <FormatQuoteIcon className={styles.activeIcon} />
                        <p className={styles.activeText}>{i["text"]}</p>
                        <div className={styles.activeSubCard}>
                          <h4>{i["name"]}</h4>
                          <p>{i["type"]}</p>
                          <NavLink
                            to={`/gallery/${i["id"]}`}
                            className={styles.galleryBtn}
                            state={{ review: i }}
                          >
                            <p>View Gallery</p>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.reviewCardDiv}>
                      <div className={styles.reviewCardInactive}>
                        <FormatQuoteIcon className={styles.inActiveIcon} />
                        <p className={styles.inActiveText}>{i["text"]}</p>
                        <div className={styles.inActiveSubCard}>
                          <h4>{i["name"]}</h4>
                          <p>{i["type"]}</p>
                        </div>
                      </div>
                    </div>
                  )
                }
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.ourWorkBtnDiv}>
          <NavLink to={`/gallery/my_images`} className={styles.ourWorkBtn}>
            See Our Works
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
