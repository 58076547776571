import { useEffect, useState } from "react";
import styles from "./Cart.module.css";
import ImageOverlay from "../utilities/ImageOverlay";
import CropFreeIcon from "@mui/icons-material/CropFree";
import Loading from "../utilities/Loading";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Cart() {
  const [cartData, setCartData] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showImageSrc, setShowImageSrc] = useState("");
  const [loading, setLoading] = useState(true);

  const handlePreviewClick = (src) => {
    setShowImage(!showImage);
    setShowImageSrc(src);
  };

  const removeFromCartHandler = async (index) => {
    if (
      window.confirm("Are you sure you want to remove this item from Cart?")
    ) {
      setLoading(true);
      var cItems = [...cartItems];
      cItems.splice(index, 1);
      setCartItems(cItems);

      const idFromLocalCache = localStorage.getItem("cartID");
      var newCartDataItems = cartData["cart_items"];
      for (let i = 0; i < newCartDataItems.length; i++) {
        if (newCartDataItems[i].id === idFromLocalCache) {
          if (cItems.length > 0) {
            newCartDataItems[i].items = cItems;
          } else {
            newCartDataItems.splice(i, 1);
          }
          break;
        }
      }
      cartData["cart_items"] = newCartDataItems;
      const updatingCartData = cartData;

      const docRef = doc(db, "basic", "cart");
      await updateDoc(docRef, updatingCartData);

      toast.success("Item removed from the Cart!", {
        position: toast.POSITION.BOTTOM_CENTER,
        style: {
          backgroundColor: "white",
          border: "2px solid #3d2d10",
          color: "#3d2d10",
        },
        icon: false,
        autoClose: 3000,
        hideProgressBar: true,
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    const idFromLocalCache = localStorage.getItem("cartID");
    // const idFromLocalCache = "12345";
    if (idFromLocalCache !== null) {
      fetchCartData(idFromLocalCache);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchCartData = async (cartID) => {
    setLoading(true);

    //fetch 'cart' doc from 'basic' collection
    const docRef = doc(db, "basic", "cart");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const fetchedData = docSnap.data();
      setCartData(fetchedData);
      const fetchedCartList = fetchedData["cart_items"];

      for (let i = 0; i < fetchedCartList.length; i++) {
        if (fetchedCartList[i].id === cartID) {
          if (fetchedCartList[i].items.length !== 0) {
            setCartItems(fetchedCartList[i].items);
          }
          break;
        }
      }
    }

    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      {showImage && (
        <ImageOverlay img={showImageSrc} handleClick={handlePreviewClick} />
      )}
      {loading ? (
        <Loading />
      ) : (
        <div className={cartItems.length === 0 ? styles.NoCart : styles.Cart}>
          {cartItems.length === 0 ? (
            <h1>Cart is Empty</h1>
          ) : (
            <div>
              <h1 className={styles.heading}>Cart</h1>
              <div className={styles.mainCartDiv}>
                {cartItems.map((i, index) => (
                  <div key={i["name"]} className={styles.cartDiv}>
                    <div className={styles.previewImgDiv}>
                      <img alt="cart_img" src={i["img"]} />
                      <div
                        onClick={() => {
                          handlePreviewClick(i["img"]);
                        }}
                        className={styles.iconDiv}
                      >
                        <CropFreeIcon className={styles.icon} />
                      </div>
                    </div>
                    <div className={styles.rightDiv}>
                      <p className={styles.name}>{i["name"]}</p>
                      <p className={styles.variant}>Group: {i["variant"]}</p>
                      <p className={styles.size}>Size: {i["size"]}</p>
                      <button
                        className={styles.removeBtn}
                        onClick={() => {
                          removeFromCartHandler(index);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <h2 className={styles.amountHeading}>
                Total {cartItems.length} Items
              </h2>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Cart;
