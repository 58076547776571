import React from "react";
import styles from "./About.module.css";
import Fade from "react-reveal";

export const About = ({ new1, new2 }) => {
  return (
    <div className={styles.aboutMainDiv}>
      <div className={styles.aboutDiv}>
        <Fade top duration={2000}>
          <div className={styles.logoTextDiv}>
            <p className={styles.mainLogoText}>
              KAIROS
              <span style={{ fontSize: "20px", marginTop: "20px" }}>®</span>
            </p>
            <p className={styles.mainLogoSubText}>Veneers &Ply</p>
          </div>
        </Fade>
        <Fade top duration={2000}>
          <p className={styles.whatNewText}>What's New Today?</p>
        </Fade>
        <div className={styles.bottomDiv}>
          <Fade duration={3000}>
            <img alt="img" src={new1} className={styles.leftImg} />
          </Fade>
          <Fade duration={3000}>
            <img alt="img" src={new2} className={styles.rightImg} />
          </Fade>
        </div>
        {/* <Fade left duration={2000}>
          <div className={styles.leftDiv}>
            <div className={styles.logoTextDiv}>
              <p className={styles.mainLogoText}>KAIROS</p>
              <p className={styles.mainLogoSubText}>Veneers &Ply</p>
            </div>
            <div style={{ height: "7vh" }} />
            <p className={styles.leftDivText}>
              This is the body of left tile. This is a very long long body which
              i am typing.
            </p>
            <div style={{ height: "5vh" }} />
            <img
              className={styles.divImg}
              alt="img"
              src="https://static.remove.bg/sample-gallery/graphics/bird-thumbnail.jpg"
            />
          </div>
        </Fade>
        <Fade top duration={2000}>
          <div className={styles.topDiv}>
            <img
              className={styles.divImg}
              alt="img"
              src="https://static.remove.bg/sample-gallery/graphics/bird-thumbnail.jpg"
            />
            <div style={{ height: "7vh" }} />
            <h1 className={styles.topDivHeading}>Lorem Ipsum Dolor Somet</h1>
            <div style={{ height: "5vh" }} />
            <p className={styles.topDivText}>
              This is the body of left tile. This is a very long long body which
              i am typing to get an idea.
            </p>
          </div>
        </Fade>
        <Fade right duration={2000}>
          {" "}
          <div className={styles.rightDiv}>
            <h1 className={styles.rightDivHeading}>Lorem Ipsum Dolor Somet</h1>
            <div style={{ height: "5vh" }} />
            <p className={styles.rightDivText}>
              This is the body of left tile. This is a very long long body which
              i am typing to get an idea.
            </p>
            <div style={{ height: "7vh" }} />{" "}
            <img
              className={styles.divImg}
              alt="img"
              src="https://static.remove.bg/sample-gallery/graphics/bird-thumbnail.jpg"
            />
          </div>
        </Fade> */}
      </div>
    </div>
  );
};
