import React from "react";
import styles from "./Dropdown.module.css";
import { NavLink } from "react-router-dom";

export const Dropdown = ({ toggleCollection, tags }) => {
  return (
    <>
      <ul className={styles.submenu}>
        {tags.map((i) => (
          <li key={i}>
            <NavLink
              to={"/tags/" + i}
              className={styles.submenuItem}
              onClick={toggleCollection}
            >
              {i}
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
};
