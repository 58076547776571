import React, { useEffect, useState } from "react";
import "./Utilities.css";
import CloseIcon from "@mui/icons-material/Close";
import { contactArchitectTitle } from "./utilities";
function disableScroll() {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

function enableScroll() {
  window.onscroll = function () {};
}

const GetContactOverlay = ({ title, handleClick, handleContactClick }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [msgText, setMsgText] = useState("");

  const handleContactBtnClick = async () => {
    if (name === "") {
      setMsgText("Please enter name.");
      return;
    }
    if (phone === "") {
      setMsgText("Please enter phone number");
      return;
    }
    await handleContactClick(name, phone);
    enableScroll();
    handleClick();
  };

  useEffect(() => {
    disableScroll();
  }, []);
  return (
    <div className="overlayDiv">
      <div
        className="iconDiv"
        onClick={() => {
          enableScroll();
          handleClick();
        }}
      >
        <CloseIcon className="icon" />
      </div>

      <div className="dialogDiv">
        <h1>{title}</h1>
        <p>
          {title === contactArchitectTitle
            ? "Please share us with your Name and Phone number which will be sent to the architect direclty."
            : "Please enter your Name and Phone number to create a cart for you."}
        </p>
        <input
          placeholder="Enter Name"
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          placeholder="Enter Phone Number"
          type="text"
          onChange={(e) => setPhone(e.target.value)}
        />
        <button onClick={handleContactBtnClick}>
          {title === contactArchitectTitle ? "Contact" : "Add to Cart"}
        </button>
        <p>{msgText}</p>
      </div>
    </div>
  );
};

export default GetContactOverlay;
