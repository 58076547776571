import { useEffect, useState } from "react";
import styles from "./Item.module.css";
import { useLocation } from "react-router-dom";
import {
  addToCartTitle,
  getCurrentFormattedTime,
  isEmpty,
  sendPushNotifications,
} from "../utilities/utilities";
import { GroupCard } from "./components/GroupCard";
import ImageOverlay from "../utilities/ImageOverlay";
import GetContactOverlay from "../utilities/GetContactOverlay";
import { db } from "../../firebase";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import Loading from "../utilities/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Item() {
  const location = useLocation();
  const itemIDFromPath = location.pathname
    .split("/")
    .at(-1)
    .replaceAll("%20", " ");

  const [item, setItem] = useState({});
  const [cartData, setCartData] = useState({});
  const [fetchedCartData, setFetchedCartData] = useState({});
  const [showImage, setShowImage] = useState(false);
  const [showImageSrc, setShowImageSrc] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [loading, setLoading] = useState(true);

  const handlePreviewClick = (src) => {
    setShowImage(!showImage);
    setShowImageSrc(src);
  };

  const handleContactClick = () => {
    setShowContactDetails(!showContactDetails);
  };

  //function to create new cart
  //fetch name and phone from the contact overlay
  //then save the created card id in local storage
  const createNewCart = async (name, phone) => {
    handleContactClick();
    setLoading(true);
    //create a new card id
    var newCartID = Math.floor(Math.random() * 90000) + 10000;

    //fetching cart data and then adding the item to it. Then uploading the cart data again
    if (isEmpty(fetchedCartData)) {
      const docRef = doc(db, "basic", "cart");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const cartDataFirestore = docSnap.data();

        //checking for if the generated id already exists
        for (let i = 0; i < cartDataFirestore["cart_items"].length; i++) {
          if (cartDataFirestore["cart_items"][i].id === newCartID) {
            newCartID = Math.floor(Math.random() * 90000) + 10000;
          }
        }

        await addToCartFirestore(
          cartData,
          newCartID,
          cartDataFirestore,
          name,
          phone
        );
        setFetchedCartData(cartDataFirestore);
      }
    } else {
      //checking for if the generated id already exists
      for (let i = 0; i < fetchedCartData["cart_items"].length; i++) {
        if (fetchedCartData["cart_items"][i].id === newCartID) {
          newCartID = Math.floor(Math.random() * 90000) + 10000;
        }
      }
      await addToCartFirestore(
        cartData,
        newCartID,
        fetchedCartData,
        name,
        phone
      );
    }

    setLoading(false);
  };

  //add items to existing cart from cart id
  const addToExistingCart = async (cartID, newCartData) => {
    setLoading(true);
    if (isEmpty(fetchedCartData)) {
      const docRef = doc(db, "basic", "cart");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const cartDataFirestore = docSnap.data();
        await addToCartFirestore(newCartData, cartID, cartDataFirestore);
        setFetchedCartData(cartDataFirestore);
      }
    } else {
      await addToCartFirestore(newCartData, cartID, fetchedCartData);
    }
    setLoading(false);
  };

  //main function to write data to cart document on firestore
  const addToCartFirestore = async (
    addingItem,
    cartID,
    fetchedCartFirestore,
    name,
    phone
  ) => {
    const cartItemsList = fetchedCartFirestore["cart_items"];
    var userCartIndex = -1;

    for (let i = 0; i < cartItemsList.length; i++) {
      if (cartItemsList[i]["id"] === cartID) {
        userCartIndex = i;
        break;
      }
    }

    //loop is run through whole list, means the user's localstorage stored id is not on firestore
    //run process to create new
    if (userCartIndex === -1 && name === undefined && phone === undefined) {
      handleContactClick();
      return;
    } else {
      if (userCartIndex !== -1) {
        //user's cart already exists on firestore
        cartItemsList[userCartIndex]["items"].push(addingItem);
      } else {
        //user's cart doesn't exists and create new
        const userNewCartData = {
          created_at: Timestamp.now(),
          id: cartID.toString(),
          name: name,
          phone: phone,
          items: [addingItem],
        };
        cartItemsList.push(userNewCartData);
      }
    }

    fetchedCartFirestore["cart_items"] = cartItemsList;
    const docRef = doc(db, "basic", "cart");
    await updateDoc(docRef, fetchedCartFirestore);

    //sending push notification
    const t = getCurrentFormattedTime();
    var title = "";
    var body = "";
    if (userCartIndex === -1) {
      title = "New Cart Added";
      body = `A cart with ID:"${cartID}" was created on ${t}`;
    } else {
      title = "New Item Added to Cart";
      body = `A new item was added to the cart with ID:"${cartID}" on ${t}`;
    }
    await sendPushNotifications(title, body);

    toast.success("Item added to Cart!", {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "white",
        border: "2px solid #3d2d10",
        color: "#3d2d10",
      },
      icon: false,
      autoClose: 3000,
      hideProgressBar: true,
    });

    //saving created cart id to local storage
    localStorage.setItem("cartID", cartID);
  };

  const handleAddToCartClick = async (variantID, selectedSize) => {
    const variant = item["variants"].find((a) => a["id"] === variantID);
    const newCartData = {
      collection: item["collection"],
      img: variant["img"],
      name: item["name"],
      size: selectedSize,
      variant: variant["grp_no"],
    };

    setCartData(newCartData);

    const cartID = localStorage.getItem("cartID");
    if (cartID === null) {
      handleContactClick();
    } else {
      addToExistingCart(cartID, newCartData);
    }
  };

  useEffect(() => {
    if (location.state === null) {
      fetchItem(itemIDFromPath);
    } else {
      setItem(location.state.item);
      setLoading(false);
    }
  }, []);

  const fetchItem = async (id) => {
    setLoading(true);

    const docRef = doc(db, "items", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const fetchedData = docSnap.data();
      setItem(fetchedData);
    }

    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      {showImage && (
        <ImageOverlay img={showImageSrc} handleClick={handlePreviewClick} />
      )}
      {showContactDetails && (
        <GetContactOverlay
          title={addToCartTitle}
          handleClick={handleContactClick}
          handleContactClick={createNewCart}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <div className={isEmpty(item) ? styles.NoItemMainDiv : styles.MainDiv}>
          {isEmpty(item) ? (
            <h1>No Data Available.</h1>
          ) : (
            <div>
              <h1 className={styles.heading}>{item["name"]}</h1>
              <div className={styles.variantMainDiv}>
                {item["variants"].map((i) => (
                  <GroupCard
                    i={i}
                    key={i["grp_no"]}
                    handlePreviewClick={handlePreviewClick}
                    handleAddToCartClick={handleAddToCartClick}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Item;
