import React, { useEffect, useState } from "react";
import styles from "./About2.module.css";
import Fade from "react-reveal";

export const About2 = ({ about1, about2 }) => {
  const [abt1Index, setAbt1Index] = useState(0);
  const [abt2Index, setAbt2Index] = useState(0);

  const handleAbout1IndexIncrease = () => {
    setInterval(() => {
      if (abt1Index + 1 === about1["img"].length) {
        setAbt1Index(0);
      } else {
        setAbt1Index(abt1Index + 1);
      }
      console.log("abt 1 index", abt1Index);
    }, 5000);
  };

  const handleAbout2IndexIncrease = () => {
    setInterval(() => {
      if (abt2Index + 1 === about2["img"].length) {
        setAbt2Index(0);
      } else {
        setAbt2Index(abt2Index + 1);
      }
      console.log("abt 2 index", abt2Index);
    }, 5000);
  };

  useEffect(() => {
    // handleAbout1IndexIncrease();
    // handleAbout2IndexIncrease();
  }, []);
  return (
    <div id="about" className={styles.aboutMainDiv}>
      <div className={styles.aboutDiv}>
        <Fade top duration={2000}>
          <h1 className={styles.mainHeading}>About us</h1>
        </Fade>
        <div className={styles.abtDiv1}>
          <Fade left duration={2000}>
            <p className={styles.abtDiv1Text}>{about1["body"]}</p>
          </Fade>
          <Fade duration={3000}>
            <img
              className={styles.abtDiv1Img}
              alt="img"
              src={about1["img"][abt1Index]}
            />
          </Fade>
        </div>
        <div className={styles.abtDiv2}>
          <Fade duration={3000}>
            <img
              className={styles.abtDiv2Img}
              alt="img"
              src={about2["img"][abt2Index]}
            />
          </Fade>
          <Fade right duration={3000}>
            <p className={styles.abtDiv2Text}>{about2["body"]}</p>
          </Fade>
        </div>
      </div>
    </div>
  );
};
